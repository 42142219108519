import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import border_top from "../images/border_top.png";
import border_middle from "../images/border_middle.png";
import border_bottom from "../images/border_bottom.png";

import apple_maps_image from "../images/apple_maps.jsx";
import google_maps_image from "../images/google_maps.svg";
import waze_image from "../images/waze.png";
import glasses_image from "../images/glasses.png";

export default function Home() {
    const navigate = useNavigate();
    const access = localStorage.getItem("access");

    useEffect(() => {
        if (access !== "2024") {
            navigate("/authenticate");
        }
    });

    const address = "3908 E Crisanta Privado, Ontario, California 91761";
    const encodedAddress = encodeURIComponent(address);

    // const isMobileDevice = () => {
    //     return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    // };

    const appleMapsURL = `maps://?q=${encodedAddress}`;
    const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    const wazeURL = `geo:0,0?q=${encodedAddress}`;

    const googleFormURL = `https://docs.google.com/forms/d/e/1FAIpQLSfadnRgISQSyZ0SaRl0Y6mvAxBGvdbf61C19MxXBtFtXhwO3g/viewform?usp=sf_link`;

    // const openMaps = () => {
    //     const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    //     // const appleMapsURL = `maps://?q=${encodedAddress}`;
    //     const geoURL = `geo:0,0?q=${encodedAddress}`;

    //     // If on a mobile device, use the geo: URI. Otherwise, use Google Maps URL.
    //     if (isMobileDevice()) {
    //         window.location.href = geoURL;
    //     } else {
    //         window.location.href = googleMapsURL;
    //     }
    // };

    return (
        <div className="page animate-fade-in">
            <div className="background">
                <img
                    className="background-image top-image"
                    src={border_top}
                    alt=""
                ></img>
                <img
                    className="background-image middle-image"
                    src={border_middle}
                    alt=""
                ></img>
                <img
                    className="background-image bottom-image"
                    src={border_bottom}
                    alt=""
                ></img>
            </div>
            <div className="content">
                <div className="py-20 sm:py-24 md:py-32 mx-12 sm:mx-auto sm:w-[440px] md:w-[568px] lg:w-[824px]">
                    <h1 className="shimmer font-title text-5xl sm:text-7xl md:text-8xl lg:text-9xl">
                        Hobson
                        <br />
                        Halloween
                        <br />
                        2024
                    </h1>
                    <div className="grid grid-cols-2 py-6 font-paragraph font-light text-white text-xl sm:text-2xl md:text-3xl">
                        <div className="col-span-2 lg:col-span-1 py-6">
                            <span className="font-bold">Date</span>
                            <p>Saturday, October 26th</p>
                        </div>
                        <div className="col-span-2 lg:col-span-1 py-6">
                            <span className="font-bold">Time</span>
                            <p>6:00 pm</p>
                        </div>
                        <div className="col-span-2 lg:col-span-1 py-6">
                            <span className="font-bold">Location</span>
                            <p>3908 E Crisanta Privado</p>
                            <p>Ontario, California 91761</p>
                            <div className="py-2">
                                <a
                                    className="inline-block mx-6 py-1 px-2.5 rounded-lg border-2 border-transparent hover:border-[#F7E09C]"
                                    href={googleMapsURL}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="h-12"
                                        src={google_maps_image}
                                        alt=""
                                    ></img>
                                </a>
                                <a
                                    className="inline-block mx-4 p-1 rounded-lg border-2 border-transparent hover:border-[#F7E09C]"
                                    href={appleMapsURL}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {apple_maps_image(48)}
                                </a>
                                <a
                                    className="inline-block mx-4 p-1 rounded-lg border-2 border-transparent hover:border-[#F7E09C]"
                                    href={wazeURL}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="h-12"
                                        src={waze_image}
                                        alt=""
                                    ></img>
                                </a>
                            </div>
                        </div>
                        <div className="col-span-2 lg:col-span-1 py-6">
                            <span className="font-bold">RSVP</span>
                            <p>RSVP by October 12th</p>
                            <br />
                            <a
                                className="border-2 rounded-md p-4 text-2xl font-bold border-[#F7E09C] hover:text-black hover:bg-[#F7E09C]"
                                href={googleFormURL}
                                target="_blank"
                                rel="noreferrer"
                            >
                                RSVP Form
                            </a>
                            <br />
                            <br />
                        </div>
                        <div className="col-span-2 py-6">
                            <span className="font-bold">Costumes</span>
                            <p>
                                Now listen here, costumes ain’t just a
                                suggestion, they’re the law of the land!
                                <br />
                                No rags? No dice!
                                <br />
                                <br />
                                If you’re all wet on ideas, don’t get in a
                                lather. A flapper dress or gangster getup will
                                do the trick just fine. Just be sure to look
                                sharp, or you'll be spifflicated in no time!
                            </p>
                        </div>
                        <div className="col-span-2 py-6">
                            <span className="font-bold">Food</span>
                            <p>
                                We’ll be serving up plenty of grub and a few
                                giggle waters to keep the night hoppin’. Expect
                                sandwiches, sides, and finger foods to keep you
                                fueled.
                                <br />
                                <br />
                                If you're thinking about bringing anything, make
                                sure you give us a jingle first. We don’t need
                                20 bottles of hooch or 10 trays of deviled eggs,
                                capisce?
                            </p>
                        </div>
                        <div className="col-span-2 py-6">
                            <span className="font-bold">Games</span>
                            <p>
                                Now, if you’re feelin’ lucky, this joint’s gonna
                                be rollin’ with casino games all night long.
                                Bring a little dough if you wanna take a crack
                                at winning big! You’ll be gambling with the best
                                of ‘em. Who knows, maybe you’ll leave with more
                                clams than you came with!
                            </p>
                        </div>
                        <hr className="col-span-2 w-1/2 my-8 mx-auto" />
                        <div className="col-span-2 py-6">
                            <p>
                                It’s gonna be the bee's knees – a night of
                                cards, costumes, and high-rolling fun, just like
                                a speakeasy should be. So don’t be a wet
                                blanket! We’ll see you there, or we'll send the
                                big boys to come lookin' for ya!
                                <br />
                                <br />
                                Cheers and speakeasy whispers,
                                <br />
                                <br />
                                <span className="font-special">
                                    Jeremy & Hannah
                                </span>
                            </p>
                            <img
                                className="w-8 mx-auto mt-12 "
                                alt="glasses"
                                src={glasses_image}
                            ></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
