import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import glasses_image from "../images/glasses.png";

export default function Authenticate() {
    const navigate = useNavigate();
    const access = localStorage.getItem("access");

    useEffect(() => {
        if (access === "2024") {
            navigate("/");
        }
    });

    function successfulLogin() {
        localStorage.setItem("access", "2024");
        navigate("/");
    }

    function checkPassword(e) {
        e.target.value = e.target.value.toUpperCase();
        e.target.value = e.target.value.replace(/[^A-Z]/g, "");
        if (e.target.value === "SWORDFISH") {
            e.target.disabled = true;
            const glasses_image = document.getElementById("glasses-image");
            const password_label = document.getElementById("password-label");
            glasses_image.classList.add("animate-fade-in-then-out");
            password_label.classList.add("animate-fade-out");
            e.target.classList.add("animate-fade-out");
            setTimeout(successfulLogin, 5000);
        }
    }

    return (
        <div className="fill-screen w-full flex justify-center items-center">
            <div>
                <h1
                    id="password-label"
                    className="font-title text-5xl sm:text-7xl md:text-8xl lg:text-9xl"
                >
                    Password
                </h1>
                <input
                    className="font-paragraph mt-4 w-[80%] max-w-none text-center text-4xl p-4 bg-transparent border-4 border-[#F7E09C] rounded-xl"
                    maxLength={10}
                    onInput={checkPassword}
                ></input>
                <img
                    id="glasses-image"
                    className="opacity-0 w-[100%] max-w-[600px] pt-6 px-20 mx-auto"
                    src={glasses_image}
                    alt="glasses"
                ></img>
            </div>
        </div>
    );
}
